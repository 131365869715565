@media (max-width: 1199.98px) {
  header {
    -webkit-background-size: cover;
    background-size: cover;
  }
  header nav ul li {
    margin-right: 20px;
  }
  .header_video_wrap .header_video_desc {
    padding-left: 150px;
    font-size: 14px;
  }
  .advantages .advantages_row_left ul {
    margin-top: 27px;
  }
  .advantages .advantages_row_right ul {
    margin-top: 20px;
  }
  .program .dots_wrap_1 {
    left: 379px;
  }
  .program .dots_wrap_2 {
    left: 279px;
  }
  .program .dots_wrap_4 {
    left: 307px;
  }
  .program .dots_wrap_8 {
    bottom: -119px;
    left: 371px;
  }
  .teacher .left_img_row > div:last-of-type {
    padding-left: 30px;
  }
  a.footer_logo {
    font-size: 19px;
  }
  .footer_nav_row > div:last-of-type {
    padding-left: 0;
  }
  .advantages > .container .bg_text,
  .teacher > .container .bg_text,
  .reviews_2 .container .bg_text {
    display: none;
  }
}


@media (max-width: 991.98px) {
  header .header_top_col {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  header nav {
    display: none;
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    padding-top: 30px;
  }
  header .header_phone_wrap span {
    display: none;
  }
  header .main_logo {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  header nav ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  header nav ul li {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .header_video_wrap {
    padding-bottom: 90px;
    background: url("../img/inst_header_bg2.png") no-repeat right 0 bottom;
  }
  .header_video_col {
    margin-top: 30px;
  }
  .header_video_wrap .left_col .start_training_btn:before {
    display: none;
  }
  .hamburger {
    outline: none !important;
    padding: 0;
  }
  .hamburger-box {
    height: 20px;
    width: 32px;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 32px;
    height: 2px;
    background-color: #ffb728;
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 8px;
  }
  .hamburger--elastic.is-active .hamburger-inner::after {
    top: 20px;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 16px;
  }
  .header_video_wrap .header_video {
    max-width: 555px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  .header_video_wrap .header_video_desc {
    display: none;
  }
  .advantages > .container {
    background: none;
  }
  .advantages .advantages_row_left,
  .advantages .advantages_row_right {
    margin-bottom: 50px;
  }
  .advantages .advantages_row_left ul,
  .advantages .advantages_row_right ul {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    border: none;
  }
  .advantages .start_training_btn {
    margin-top: 0;
  }
  .program .dots_wrap_1,
  .program .dots_wrap_2,
  .program .dots_wrap_4,
  .program .dots_wrap_6,
  .program .dots_wrap_8 {
    display: none;
  }
  .program .left_img_row .img_program_wrap,
  .program .right_img_row .img_program_wrap {
    margin-bottom: 70px;
  }
  .program .left_img_row .program_item_desc,
  .program .right_img_row .program_item_desc {
    max-width: 438px;
    margin-left: auto;
    margin-right: auto;
  }
  .start_training_btn {
    max-width: 288px;
    width: 100%;
  }
  .program .start_training_btn {
    margin-bottom: 120px;
  }
  .teacher .left_img_row .teacher_img_wrap {
    margin-left: auto;
    margin-right: auto;
  }
  .teacher .right_img_row ul {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
  .teacher .left_img_row ul {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
  .teacher .left_img_row h4 {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
  .teacher .right_img_row {
    margin-bottom: 80px;
  }
  .teacher .left_img_row {
    margin-bottom: 80px;
  }
  .reviews_1 .container {
    background: none;
  }
  .reviews .header_video {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
  .reviews_video_col .video_desc {
    background: none;
  }
  .reviews_video_col .video_desc {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
  }
  .reviews_2 .container {
    background: none;
  }
  .reviews .start_training_btn {
    margin-top: 0;
  }
  .how_start .start_training_btn {
    position: relative;
    top: 0;
    margin-left: 50%;
    left: -144px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .how_start .item_left_row {
    margin-bottom: 50px;
  }
  .how_start .item_right_row {
    margin-bottom: 50px;
  }
  .training_price .rate_col:nth-of-type(1) .rate {
    margin-left: auto;
    margin-right: auto;
  }
  .training_price .rate_col:nth-of-type(3) .rate {
    margin-left: auto;
    margin-right: auto;
  }
  .footer_social {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  .conf_wrap {
    text-align: center;
  }
  .faq ul {
    padding-left: 25px;
    padding-right: 25px;
  }
  header .header_video_wrap .subscribe_it p {
    max-width: 100%;
    text-align: center;
  }
  header .footer_social {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .advantages .social_wrap {
    margin-top: 0;
  }
  .header_video_wrap {
    background: url(../img/inst_header_bg2.png) no-repeat right 0 bottom;
  }
  .advantages_video > .container {
    padding-bottom: 0;
  }
  .program_video .social_wrap {
    margin-bottom: 0;
  }
  .teacher_video {
    padding-bottom: 40px;
  }

  .reviews h2 {
    text-align: center;
  }

  .slick-slide img {
    margin-left: auto;
    margin-right: auto;
  }

  .next_arrow {
    right: 30px;
    left: auto;
  }

  .reviews_video_col .video_desc h4 {
    text-align: center;
  }


  .reviews_video_col .video_desc p {
    text-align: center;
  }

  .review_desc p {
    text-align: center;
  }
}














@media (max-width: 767.98px) {
  .acquirement h2 span:after {
    display: none;
  }
  .img_left_row .acquirement_desc {
    margin-left: auto;
    margin-right: auto;
  }
  .header_video_wrap {
    background: none;
  }
}

















@media (max-width: 575.98px) {
  .hamburger {
    height: 18px;
  }
  .hamburger-box {
    width: 24px;
  }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 24px;
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 7px;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 14px;
  }
  header nav ul li a {
    font-size: 14px;
  }
  header nav ul li {
    margin-bottom: 12px;
  }
  header .main_logo {
    font-size: 14px;
    line-height: 18px;
  }
  header .header_phone_wrap p {
    font-size: 18px;
  }
  .header_video_wrap h2 {
    margin-bottom: 15px;
    font-size: 26px;
    line-height: 34px;
  }
  .header_video_col {
    margin-top: 8px;
  }
  .header_video_wrap h2 b:after {
    width: 76px;
  }
  .header_video_wrap .left_col p {
    font-size: 16px;
    line-height: 22px;
  }
  .start_training_btn {
    font-size: 16px;
    line-height: 55px;
  }
  .header_video_wrap {
    padding-top: 117px;
    padding-bottom: 81px;
    background: url(../img/inst_header_bg2.png) no-repeat right -115px bottom;
  }
  .advantages > .container {
    padding-top: 72px;
    padding-bottom: 86px;
  }
  .advantages h2 {
    margin-bottom: 72px;
    font-size: 26px;
    line-height: 34px;
  }
  .advantages .advantages_row_left ul, .advantages .advantages_row_right ul {
    margin-top: 0;
    padding-top: 34px;
  }
  .advantages .advantages_row_left {
    margin-bottom: 29px;
  }
  .advantages .advantages_row_right {
    margin-bottom: 0;
  }
  .advantages h2 span:after {
    width: 125px;
    bottom: 0;
    right: -3px;
  }
  .advantages .advantages_row_left ul li,
  .advantages .advantages_row_right ul li {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .program h2 {
    margin-bottom: 61px;
    font-size: 26px;
  }
  .program h2 span:after {
    width: 104px;
    bottom: 0;
    right: -2px;
  }
  .program .left_img_row .img_program_wrap .num_program,
  .program .right_img_row .img_program_wrap .num_program {
    font-size: 64px;
    top: 6px;
  }
  .program .left_img_row .img_program_wrap, .program .right_img_row .img_program_wrap {
    margin-bottom: 42px;
  }
  .program .left_img_row h3,
  .program .right_img_row h3 {
    font-size: 24px;
    line-height: 28px;
  }
  .program {
    padding-top: 74px;
  }
  .program .left_img_row, .program .right_img_row {
    margin-bottom: 65px;
  }
  .program .start_training_btn {
    margin-bottom: 61px;
  }
  .teacher {
    padding-top: 74px;
    padding-bottom: 86px;
  }
  .teacher h2 {
    margin-bottom: 73px;
    font-size: 26px;
  }
  .teacher h2 span:after {
    width: 138px;
    right: -1px;
    bottom: 0;
  }
  .teacher .right_img_row,
  .teacher .left_img_row {
    margin-bottom: 40px;
  }
  .teacher .right_img_row .teacher_img_wrap .teacher_flag img,
  .teacher .left_img_row .teacher_img_wrap .teacher_flag img {
    max-width: 168px;
  }
  .teacher .right_img_row .teacher_img_wrap .teacher_flag .flag_desc,
  .teacher .left_img_row .teacher_img_wrap .teacher_flag .flag_desc {
    top: -99px;
  }
  .teacher .right_img_row .teacher_img_wrap .teacher_flag .flag_desc h5,
  .teacher .left_img_row .teacher_img_wrap .teacher_flag .flag_desc h5 {
    font-size: 20px;
    line-height: 18px;
  }
  .teacher .right_img_row .teacher_img_wrap .teacher_flag .flag_desc h6,
  .teacher .left_img_row .teacher_img_wrap .teacher_flag .flag_desc h6 {
    font-size: 15px;
  }
  .teacher .left_img_row > div:last-of-type {
    padding-left: 0;
  }
  .teacher .left_img_row h4 {
    font-size: 18px;
  }
  .reviews h2 {
    margin-bottom: 73px;
    font-size: 26px;
  }
  .reviews h2 span:after {
    width: 74px;
    bottom: 0;
    right: -3px;
  }
  .reviews .header_video .youCover img {
    height: auto;
    width: 100%;
  }
  .reviews_video_col .video_desc h4 {
    font-size: 16px;
  }
  .reviews_1 .container {
    padding-top: 74px;
    padding-bottom: 82px;
  }
  .benefits h2 {
    margin-bottom: 86px;
    font-size: 26px;
  }
  .benefits h2 span:after {
    width: 80px;
    bottom: 0;
    right: -2px;
  }
  .benefits .ben_left_img_row h4,
  .benefits .ben_right_img_row h4 {
    font-size: 18px;
  }
  .benefits .ben_left_img_row p,
  .benefits .ben_right_img_row p {
    font-size: 16px;
    line-height: 22px;
  }
  .benefits {
    padding-top: 74px;
  }
  .benefits_4 {
    padding-bottom: 86px;
  }
  .acquirement h2 {
    margin-bottom: 70px;
    font-size: 26px;
  }
  .img_right_row h4,
  .img_left_row h4 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .acquirement {
    padding-top: 74px;
  }
  .img_right_row {
    margin-bottom: 60px;
  }
  .acquirement .start_training_btn {
    margin-top: 30px;
  }
  .acquirement_2 {
    padding-bottom: 86px;
  }
  .how_start h2 {
    margin-bottom: 73px;
    font-size: 26px;
  }
  .how_start h2 span:after {
    width: 68px;
    bottom: 0;
    right: -3px;
  }
  .how_start .item_left_row .start_desc_wrap .num_start,
  .how_start .item_right_row .start_desc_wrap .num_start {
    left: 20px;
    font-size: 26px;
  }
  .how_start .item_left_row .start_desc_wrap,
  .how_start .item_right_row .start_desc_wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
  .how_start .start_training_btn {
    max-width: 210px;
    margin-top: 0;
    margin-bottom: 55px;
    margin-left: 50%;
    left: -68px;
  }
  .how_start > .container {
    padding-top: 72px;
    padding-bottom: 29px;
  }
  .training_price h2 {
    font-size: 26px;
  }
  .training_price h2 span:after {
    width: 52px;
    bottom: 0;
    right: -2px;
  }
  .training_price h3 {
    font-size: 26px;
  }
  .training_price .rate .start_training_btn {
    max-width: 200px;
  }
  .training_price > .container .price_bg1,
  .training_price > .container .price_bg2 {
    display: none;
  }
  .faq h2 {
    font-size: 26px;
  }
  .faq h2 span:after {
    width: 82px;
    bottom: 0;
    right: -4px;
  }
  .faq > .container {
    background: none;
  }
  .faq ul {
    padding-left: 0;
    padding-right: 0;
  }
  .faq .start_training_btn {
    margin-top: 52px;
    margin-bottom: 86px;
  }
  .footer_phone {
    width: auto;
    text-align: right;
  }
  a.footer_logo {
    font-size: 14px;
  }
  .footer_phone p {
    font-size: 18px;
  }
  .footer_phone span {
    display: none;
  }
  .footer_social span {
    display: block;
    width: 100%;
    margin-bottom: 22px;
    margin-right: 0;
  }
  .conf_wrap {
    text-align: left;
  }
  footer {
    padding-top: 24px;
    padding-bottom: 42px;
  }
  .footer_nav_row {
    margin-bottom: 36px;
  }
  .footer_social {
    margin-bottom: 34px;
  }
  .header_video_page {
    background: none;
    padding-bottom: 16px;
  }
  .advantages_video > .container {
    padding-bottom: 46px;
  }
  .program_video {
    padding-bottom: 45px;
  }
  .teacher_video {
    padding-bottom: 46px;
  }
  .reviews_video {
    padding-bottom: 0;
  }
  .reviews_video .container {
    padding-bottom: 0;
  }
  .reviews_video:last-of-type .container {
    padding-bottom: 50px;
  }
  header .header_video_wrap .subscribe_it p,
  .reviews_video .social_wrap .subscribe_it p,
  .advantages .social_wrap .subscribe_it p,
  .program_video .social_wrap .subscribe_it p,
  .teacher_video .social_wrap .subscribe_it p {
    font-size: 16px;
  }
  .thank_you .header_video_wrap h2 {
    font-size: 26px;
  }
  .thank_you .header_video_wrap h2 span:after {
    width: 83px;
    bottom: 0;
  }
  .thank_you .header_video_wrap h4 {
    font-size: 18px;
  }
  .thank_desc p {
    font-size: 16px;
  }
  .thank_phone {
    font-size: 16px;
  }
  .thank_you .header_video_wrap h4:first-of-type {
    margin-bottom: 3px;
  }
  .thank_you .header_video_wrap h4:last-of-type {
    line-height: 24px;
  }
  .thank_you .header_video_wrap {
    padding-top: 117px;
    padding-bottom: 74px;
  }
  .thank_you .header_video_wrap h2 {
    margin-bottom: 53px;
  }
  .thank_you .header_video_wrap h4:last-of-type {
    margin-bottom: 90px;
  }
  .thank_you .footer_social {
    margin-bottom: 24px;
  }

  /*CUSTOM*/

  .training_price h4 {
    font-size: 16px;
  }

  .program h5 {
    font-size: 18px;
  }

  header .main_logo {
    max-width: 137px;
  }
}

